import { graphql, Link, PageProps } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import SEO from '@/components/seo';
import CDNReplace from '@/components/CDNReplace';
import UILabel from '@/components/UI/UILabel';
import hoverCircle from '@/components/Interaction/hoverCircle';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import * as Util from '@/components/Util';
import { Context } from '@/components/Layout';

import * as localNav from '@/styles/module/local-nav.module.scss';
import * as pageHeaderCategory from '@/styles/object/page-header-category.module.scss';

const WorksIndex: React.FC<PageProps<GatsbyTypes.WorksIndexQuery>> = (
  props,
) => {
  const { data } = props;
  const loading = useContext(Context);
  useEffect(() => {
    hoverCircle();
  }, []);

  return (
    !loading && (
      <>
        <SEO title="事例・書籍紹介" />
        <div className="page-wrap --works">
          <div className="page-wrap__wave" />
          <div className="page-wrap__bg" />
          <header className={pageHeaderCategory.page_header_category}>
            <h1>
              <small
                className={pageHeaderCategory.page_header_category__head_text}
              >
                事例・書籍紹介
              </small>
              <div className={pageHeaderCategory.page_header_category__text}>
                Works
              </div>
            </h1>
            <p className={pageHeaderCategory.page_header_category__lead}>
              NRIネットコムが携わった顧客事例・書籍をご紹介します。
            </p>
          </header>
          <div className="page-contents-box">
            <section className="section-border">
              <h3 className="title-lv1-01">
                <p className="title-lv1-01__head">顧客事例</p>
                <p className="title-lv1-01__title">Case</p>
                <div className="title-lv1-01__button">
                  <Link
                    to="/cases/"
                    className={`${localNav.local_nav_target} js-hover-circle`}
                    data-circle-type="stick"
                    data-circle-inner="arrow-right"
                  >
                    View More
                  </Link>
                </div>
              </h3>
              <div className="box-3rows-02">
                {data.allMicrocmsCase.edges.map(({ node }) => (
                  <Link
                    key={node.caseId}
                    to={`/cases/${node.caseId}/`}
                    className="box-3rows-02__item item-card-02 js-hover-circle"
                    data-circle-type=""
                    data-circle-inner="arrow-right"
                  >
                    <figure className="item-card-02__fig">
                      <img
                        loading="lazy"
                        src={`${CDNReplace(
                          node.thumbnail
                            ? node.thumbnail.url
                            : data.microcmsEnv.commonNoImage.url,
                        )}?fit=crop&w=320&h=180&auto=format&q=75`}
                        alt=""
                      />
                    </figure>
                    <div className="item-card-02__main">
                      <div className="item-card-02__tag">
                        {node.tag.map(({ tag, id }) => (
                          <UILabel key={id} text={tag} />
                        ))}
                      </div>
                      <h4 className="item-card-02__title">{node.title}</h4>
                      <p className="item-card-02__text">{node.customer}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </section>
            <section className="section-border">
              <h3 className="title-lv1-01">
                <p className="title-lv1-01__head">書籍紹介</p>
                <p className="title-lv1-01__title">Books</p>
                <div className="title-lv1-01__button">
                  <Link
                    to="/books/"
                    className={`${localNav.local_nav_target} js-hover-circle`}
                    data-circle-type="stick"
                    data-circle-inner="arrow-right"
                  >
                    View More
                  </Link>
                </div>
              </h3>
              <div className="box-3rows-02">
                {data.allMicrocmsBooks.edges.map(({ node }) => (
                  <Link
                    key={node.booksId}
                    to={`/books/${node.booksId}/`}
                    className="box-3rows-02__item item-card-02 js-hover-circle"
                    data-circle-type=""
                    data-circle-inner="arrow-right"
                  >
                    <figure className="item-card-02__fig">
                      <img
                        loading="lazy"
                        src={`${CDNReplace(
                          node.image.url,
                        )}?w=320&pad-top=20&fill=fill&fit=crop&fill-color=d9e2e6&h=180&blend-x=40&blend-fit=scale&bg=d9e2e6&trim=auto&crop=top&pad-left=90&pad-right=90&q=75`}
                        alt=""
                      />
                    </figure>
                    <div className="item-card-02__main">
                      <div className="item-card-02__tag">
                        {node.tag.map(({ tag, id }) => (
                          <UILabel key={id} text={tag} />
                        ))}
                      </div>
                      <h4 className="item-card-02__title">{node.title}</h4>
                      <p className="item-card-02__text">
                        {Util.concatTxt(Util.deleteTag(node.content), 63)}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </section>
          </div>
        </div>
        <UIBreadcrumb UpperItem={[]} CurrentItem="Works" />
      </>
    )
  );
};

export const query = graphql`
  query WorksIndex {
    allMicrocmsBooks(sort: { order: DESC, fields: issuedDate }, limit: 3) {
      edges {
        node {
          image {
            url
          }
          tag {
            id
            tag
          }
          title
          content
          booksId
          issuedDate
        }
      }
    }
    allMicrocmsCase(
      sort: { order: DESC, fields: publishedAt }
      limit: 3
      filter: { caseId: { ne: "template" } }
    ) {
      edges {
        node {
          caseId
          category
          customer
          title
          thumbnail {
            url
          }
          tag {
            tag
            id
          }
          publishedAt
        }
      }
    }
    microcmsEnv {
      commonNoImage {
        url
      }
    }
  }
`;

export default WorksIndex;
